<template>
  <div class="pa-product-filters">

    <div class="filters-container">

      <div class="category-filter">

        <div class="category-title">
          Select A Category:
        </div>

        <div class="radio-control" v-for="(category, index) in categoryStates" :key="index">
          <input type="radio" name="categories" :id="category.name" :value="category.value"
                 v-model="categoryStates[index].value" @change="onCategorySelect(index)"
                 :checked="currentState === category.id"/>
          <label :for="category.name">{{ category.text }}</label>
        </div>
      </div>

      <form :class="currentState">
        <div class="filters" :class="filterSelectClass">
          <div class="select-control"
               v-for="(categorySelect, categoryName, ii) in categorySelects"
               v-show="categorySelects[categoryName].visible">

            <div v-show="categoryName === 'manufacturer' && loading['manufacturers']" class="loader" >
              <div class="spinner"></div>
            </div>

            <div v-show="categoryName === 'series' && loading['series']" class="loader" >
              <div class="spinner"></div>
            </div>

            <div v-show="categoryName === 'models' && loading['models']" class="loader">
              <div class="spinner"></div>
            </div>

            <div v-show="categoryName === 'series' && loading['accessories']" class="loader">
              <div class="spinner"></div>
            </div>

            <div v-show="categoryName === 'models' && loading['endoscopes']" class="loader">
              <div class="spinner"></div>
            </div>

            <!--            <div v-show="categoryName === 'manufacturer' && loading['equipment']" class="loader">-->
            <!--              <div class="spinner"></div>-->
            <!--            </div>-->

            <!--            <div v-show="categoryName === 'manufacturer' && loading['equipment-models']" class="loader">-->
            <!--              <div class="spinner"></div>-->
            <!--            </div>-->

            <select :id="categorySelect.id" :name="categoryName" :key="ii" :ref="categoryName"
                    :disabled="categorySelects[categoryName].disabled === true" v-model="filters[categoryName]"
                    @change="e => onSelectChange(e, categoryName)">
              <option value="">{{ selectedLabel(categoryName) }}</option>

              <option v-for="(item, itemKey) in categorySelect.items" :key="itemKey" :value="item.name">{{
                  item.text
                }}
              </option>
            </select>
          </div>
        </div>

        <button type="button" class="button tertiary" @click.prevent="filterProducts">SHOP NOW</button>
      </form>

    </div>

  </div>
</template>

<script>
import axios from "axios";
import Spinner from "./Spinner";

export default {
  name: "PaProductFilters",
  components: {
    Spinner
  },
  mounted() {
    console.log("PaProductFilters mounted");
    this.getManufacturers();
    this.allStates["initial"](this);
  },
  data() {
    return {
      loading: {
        "flexible-endoscope-repair-parts": false,
        "manufacturers": false,
        "series": false,
        "models": false,
        "accessories": false,
        "endoscopes": false,
        "equipment": false,
        "equipment-models": false,
      },
      currentState: 'flexible-endoscope-repair-parts',
      categoryStates: [
        {
          id: 'flexible-endoscope-repair-parts',
          name: 'flexible-endoscope-repair-parts',
          value: 'flexible-endoscope-repair-parts',
          text: 'Endoscope Repair Parts'
        },
        {
          id: 'accessories',
          name: 'accessories',
          value: 'accessories',
          text: 'Accessories'
        },
        {
          id: 'endoscopes',
          name: 'endoscopes',
          value: 'endoscopes',
          text: 'Endoscopes'
        },
        {
          id: 'equipment',
          name: 'equipment',
          value: 'equipment',
          text: 'Equipment'
        },
        {
          id: 'equipment-models',
          name: 'equipment-models',
          value: 'equipment-models',
          text: 'Equipment Models'
        }
      ],
      allStates: {
        "initial": function (that) {

          // do we have the manufacturer selected already?
          // do we have the series selected already?
          // do we have the model selected already?

          that.filterSelectClass = 'three-selects';
          that.filters['product-category'] = 'flexible-endoscope-repair-parts';
          console.log("initial && endoscope-repair-parts state");

          that.categorySelects = {
            "manufacturer": {
              disabled: false,
              visible: true,
              items: []
            },
            "series": {
              disabled: that.filters.manufacturer.length === 0,
              visible: true,
              items: []
            },
            "models": {
              disabled: that.filters.series.length === 0,
              visible: true,
              items: []
            },
          };

        },
        "flexible-endoscope-repair-parts": function (that) {

          that.filterSelectClass = 'three-selects';

          that.categorySelects = {
            "manufacturer": {
              disabled: false,
              visible: true,
              items: []
            },
            "series": {
              disabled: that.filters.manufacturer.length === 0,
              visible: true,
              items: []
            },
            "models": {
              disabled: that.filters.series.length === 0,
              visible: true,
              items: []
            },
          };

        },
        "accessories": (that) => {
          console.log("accessories");

          that.filterSelectClass = 'two-selects';
          that.filters['product-category'] = 'accessories';

          that.categorySelects = {
            "manufacturer": {
              disabled: false,
              visible: true,
              items: []
            },
            "subcategories": {
              disabled: that.filters.manufacturer.length === 0,
              visible: true,
              items: []
            },
          };

        },
        "endoscopes": (that) => {
          // enable and show manufacturers

          that.filterSelectClass = 'three-selects';
          that.filters['product-category'] = 'endoscopes';

          that.categorySelects = {
            "manufacturer": {
              disabled: false,
              visible: true,
              items: []
            },
            "series": {
              disabled: that.filters.manufacturer.length === 0,
              visible: true,
              items: []
            },
            "models": {
              disabled: that.filters.series.length === 0,
              visible: true,
              items: []
            },
          };
        },
        "equipment": (that) => {
          console.log("equipment");

          that.filterSelectClass = 'two-selects';
          that.filters['product-category'] = 'equipment';

          that.categorySelects = {
            "manufacturer": {
              disabled: false,
              visible: true,
              items: []
            },
            "equipment-models": {
              disabled: that.filters.manufacturer.length === 0,
              visible: true,
              items: []
            },
          };
        },
        "equipment-models": (that) => {
          console.log("equipment-models");

          that.filterSelectClass = 'two-selects';
          that.filters['product-category'] = 'equipment-models';

          that.categorySelects = {
            "manufacturer": {
              disabled: false,
              visible: true,
              items: []
            },
            "equipment-models": {
              disabled: that.filters.manufacturer.length === 0,
              visible: true,
              items: []
            },
          };
        },
      },
      categorySelects: {
        "manufacturer": {
          disabled: false,
          visible: true,
          items: []
        },
        "series": {
          disabled: true,
          visible: true,
          items: []
        },
        "models": {
          disabled: true,
          visible: true,
          items: []
        },
        "subcategories": {
          disabled: false,
          visible: false,
          items: []
        },
        "equipment-models": {
          disabled: false,
          visible: false,
          items: []
        },
      },
      filters: {
        'product-category': 'flexible-endoscope-repair-parts',
        manufacturer: '',
        series: '',
        models: '',
        subcategories: '',
        "equipment-models": ''
      },
      action: '',
      filterSelectClass: 'three-selects',
      showLoader: true,
      timeoutAfter: 30000,
    }
  },
  methods: {
    debug(val) {
      console.log(`value: ${val}`);
      return true;
    },
    filterProducts: async function () {
      let url = `${window.PaProductFilters.filter_url}/?`;
      let params = [];

      console.log(this.filters)
      console.table(Object.entries(this.filters))

      for (const key in this.filters) {
        if (this.filters[key] !== '') {
          params.push(`${key}=${this.filters[key]}`)
        }
      }

      debugger;

      if (params.length) {
        url += params.join("&")
      }

      // this.action = url;
      location.href = url
    },
    async getManufacturers() {

      /*
       * Endoscope Repair Parts (Most Popular - Default) Main  (Category, Woocommerce product_category)
       *  Manufacturer
       *  Series
       *  Model (optional)
       *
       * POST
       * product-category=endoscope-repair-parts&filters=product-category&manufacturer=$man
       *
      */

      let formData = new FormData();
      formData.append('product-category', this.filters['product-category']);
      formData.append('filters', 'manufacturer');

      const url = window.PaProductFilters.restAPIs.filters;

      try {

        // this.showLoader = true;

        this.loading['manufacturers'] = true;

        const response = await axios.post(url, formData, {
          timeout: this.timeoutAfter,
        });

        if (response.data.success) {
          this.categorySelects.manufacturer.items = response.data.data;
          this.loading['manufacturers'] = false;
          console.log(response);
        } else {
          console.log('we go some data');
        }

      } catch (e) {
        console.error(e);
      } finally {
        //   this.showLoader = false;
      }

    },
    async getSeries() {

      /*
       * Endoscope Repair Parts (Most Popular - Default) Main  (Category, Woocommerce product_category)
       *  Manufacturer
       *  Series
       *  Model (optional)
       *
       * POST
       * product-category=endoscope-repair-parts&filters=manufacturer&manufacturer=$man
       *
       */

      let formData = new FormData();

      formData.append('product-category', this.filters['product-category']);
      formData.append('filters', 'manufacturer');
      formData.append('manufacturer', this.filters.manufacturer);

      const url = window.PaProductFilters.restAPIs.filters;

      try {

        this.showLoader = true;

        this.categorySelects.series.disabled = true;
        this.categorySelects.series.items = [];
        this.loading['series'] = true;

        const response = await axios.post(url, formData,{
          timeout: this.timeoutAfter,
        });

        if (response.data.success) {
          this.loading['series'] = false;
          this.categorySelects.series.items = response.data.data;
          this.categorySelects.series.disabled = false;
          this.showLoader = false;
          console.log(response);
        } else {
          console.log('we go some data');
        }

      } catch (e) {
        console.error(e);
      } finally {
        this.showLoader = false;
      }

    },
    async getModels() {

      /*
       * Endoscope Repair Parts (Most Popular - Default) Main  (Category, Woocommerce product_category)
       *  Manufacturer
       *  Series
       *  Model (optional)
       *
       * POST
       * product-category=endoscope-repair-parts&filters=manufacturer,series&manufacturer=$man&series=$ser
       *
       */

      let formData = new FormData();
      formData.append('product-category', this.filters['product-category']);
      formData.append('filters', 'manufacturer, series');
      formData.append('manufacturer', this.filters.manufacturer);
      formData.append('series', this.filters.series);

      const url = window.PaProductFilters.restAPIs.filters;

      try {
        this.showLoader = true;
        this.categorySelects.models.disabled = true;
        this.categorySelects.models.items = [];
        this.loading['models'] = true;

        const response = await axios.post(url, formData,{
          timeout: this.timeoutAfter,
        });

        if (response.data.success) {
          this.loading['models'] = false;
          this.categorySelects.models.items = response.data.data;
          this.categorySelects.models.disabled = false;
          this.showLoader = false;
          console.log(response);
        } else {
          console.log('we go some data');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.showLoader = false;
      }

    }, async getEquipmentModels() {

      /*
       * Endoscope Repair Parts (Most Popular - Default) Main  (Category, Woocommerce product_category)
       *  Manufacturer
       *  Series
       *  Model (optional)
       *
       * POST
       * product-category=endoscope-repair-parts&filters=manufacturer,equipment-models&manufacturer=$man
       *
       */

      let formData = new FormData();
      formData.append('product-category', this.filters['product-category']);
      formData.append('filters', 'manufacturer, equipment-models');
      formData.append('manufacturer', this.filters.manufacturer);

      const url = window.PaProductFilters.restAPIs.filters;

      try {
        this.showLoader = true;
        const response = await axios.post(url, formData, {
          timeout: this.timeoutAfter,
        });

        if (response.data.success) {
          this.categorySelects.models.items = response.data.data;
          this.showLoader = false;
          console.log(response);
        } else {
          console.log('we go some data');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.showLoader = false;
      }

    },
    onCategorySelect(index) {

      this.currentState = this.categoryStates[index].id

      // reset on category change

      this.filters = {
        'product-category': this.currentState,
        manufacturer: '',
        series: '',
        models: '',
        subcategories: '',
        "equipment-models": ''
      };

      this.onStateChange(this.categoryStates[index].id)
    },
    checkEndoscopeRepairParts(selectedTargetName, value) {

      // should implement state machine method for currentStates
      if (selectedTargetName === 'manufacturer') {
        if (value.length === 0) {
          this.categorySelects.series.disabled = true;
          this.categorySelects.models.disabled = true;
        } else {
          // okay we got a manufacturer
          // so let's get our series
          this.getSeries();
        }
      } else if (selectedTargetName === 'series') {
        if (value.length === 0) {
          this.categorySelects.models.disabled = true;
        } else {
          // okay we got a series
          // so let's get our models
          this.getModels();
        }
      }
    },
    checkEndoscopes(selectedTargetName, value) {
      this.categorySelects.series.disabled = this.filters.manufacturer.length === 0;
      this.categorySelects.models.disabled = this.filters.series.length === 0;
      // should implement state machine method for currentStates
      if (selectedTargetName === 'manufacturer') {
        if (value.length === 0) {
          this.categorySelects.series.disabled = true;
          this.categorySelects.models.disabled = true;
        } else {
          // okay we got a manufacturer
          // so let's get our series
          this.getSeries();
        }
      } else if (selectedTargetName === 'series') {
        if (value.length === 0) {
          this.categorySelects.models.disabled = true;
        } else {
          // okay we got a series
          // so let's get our models
          this.getModels();
        }
      }
    },
    checkAccessories(selectedTargetName, value) {
      this.categorySelects.subcategories.disabled = this.filters.manufacturer.length === 0;
      // should implement state machine method for currentStates
      if (selectedTargetName === 'manufacturer' && value.length === 0) {
        if (value.length === 0) {
          this.categorySelects.subcategories.disabled = true;
        }
      }
    },
    checkEquipment(selectedTargetName, value) {
      this.categorySelects["equipment-models"].disabled = this.filters.manufacturer.length === 0;
      // should implement state machine method for currentStates
      if (selectedTargetName === 'manufacturer' && value.length === 0) {
        if (value.length === 0) {
          this.categorySelects["equipment-models"].disabled = true;
        }
      }
    },
    checkEquipmentModels(selectedTargetName, value) {
      this.categorySelects["equipment-models"].disabled = this.filters.manufacturer.length === 0;

      // should implement state machine method for currentStates
      if (selectedTargetName === 'manufacturer') {
        if (value.length === 0) {
          this.categorySelects.series.disabled = true;
          this.categorySelects.models.disabled = true;
        } else {
          // okay we got a manufacturer
          // so let's get our series
          this.getSeries();
        }
      } else if (selectedTargetName === 'equipment-models') {
        if (value.length === 0) {
          this.categorySelects.models.disabled = true;
        } else {
          // okay we got a series
          // so let's get our models
          this.getEquipmentModels();
        }
      }
    },
    onSelectChange(e, selectedTargetName) {
      const value = e.target.value;

      console.log({selectedTargetName, value});

      if (this.currentState === 'flexible-endoscope-repair-parts') {
        this.checkEndoscopeRepairParts(selectedTargetName, value);
      } else if (this.currentState === 'accessories') {
        this.checkAccessories(selectedTargetName, value);
      } else if (this.currentState === 'endoscopes') {
        this.checkEndoscopes(selectedTargetName, value);
      } else if (this.currentState === 'equipment') {
        this.checkEquipment(selectedTargetName, value);
      } else if (this.currentState === 'equipment-models') {
        this.checkEquipmentModels(selectedTargetName, value);
      } else {
        console.log('no state selected');
      }
    },
    onStateChange(newState) {
      this.currentState = newState;
      this.allStates[newState](this);
      this.getManufacturers();
    },
    selectedLabel(name) {

      if (name === 'models') {
        name = name + ' (optional)';
      } else if (name === 'series') {
        name = name + ' (optional)';
      }

      return name.replace(/-/g, ' ').split(' ').map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    }
  },
}
</script>
